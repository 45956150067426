import React from 'react';

const ContactUsForm = () => {
  return (
    <div className='block p-6 rounded-lg shadow-lg bg-white w-full'>
      <form method='POST' data-netlify='true'>
        <input type='hidden' name='form-name' value='Contact Form' />
        <div className=' flex flex-col items-center'>
          <div className='form-group '>
            <div className='grid grid-rows-2 grid-cols-2 space-y-4 space-x-4 grid-flow-dense'>
              <input
                type='name'
                className='form-control
                    block
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in
                    m-0
                    mt-4
                    ml-4
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
                id='fname'
                name='fname'
                placeholder='First Name'
              />
              <input
                type='name'
                className='form-control
                    block
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
                id='lname'
                name='lname'
                placeholder='Last Name'
              />
              <input
                type='tel'
                className='form-control
                    block
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
                id='phone'
                name='phone'
                placeholder='Phone'
              />
              <input
                type='email'
                className='form-control
                    block
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none'
                id='email'
                name='email'
                placeholder='Email'
              />
              <textarea
                className='form-control
                    block
                    col-span-2
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                    resize-y'
                id='message'
                name='message'
                placeholder='How can we help?'
              />
            </div>
          </div>
          <div className='form-group form-check mb-6'></div>
          <button
            type='submit'
            className='px-6 py-2.5 bg-primary text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-primaryLight hover:shadow-lg transition duration-150 ease-in-out'
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactUsForm;
