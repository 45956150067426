import * as React from 'react';

import { FolderOpenIcon, PhoneIcon } from '@heroicons/react/outline';

import CommonLayout from '../components/home/commonLayout';
import ContactUsForm from '../components/contactUsForm';
import Layout from '../components/layout';
import PharmCorpHeading from '../components/typography/heading';
import SEOTag from '../components/seo/seo';

// markup
const ContactPage = () => {
  return (
    <Layout>
      <SEOTag title='Contact | Pharmcorps' />
      <CommonLayout>
        <PharmCorpHeading
          lightText='Contact'
          boldText='Us'
          extraClassNames='mt-4'
          underLined={true}
        />
        <div className='w-14 h-0.5 md:h-1 md:w-20 lg:w-32  mb-10 '></div>
        <div className='grid grid-cols-1 sm:grid-cols-2 w-full mb-10 space-y-4 space-x-0 sm:space-x-8'>
          <div className='flex flex-col justify-center  items-center'>
            <div className=' p-4 '>
              Get in touch with us and let us know how we can help you. Our team
              will respond to your query within 24 hours.
            </div>
            <div className='w-full rounded-full '>
              <ContactUsForm />
            </div>
          </div>
          <div className='flex flex-col bg-gray-100 rounded-3xl py-20 px-10 justify-center h-32 space-y-5   '>
            <div>Or reach out directly to us</div>
            <div className='flex space-x-2'>
              <PhoneIcon className='w-5' />
              <div className='font-bold hover:text-primary'>
                <a href='tel:+17278043557'>(727)-804-3557</a>
              </div>
            </div>
            <div className='flex space-x-2'>
              <FolderOpenIcon className='w-5' style={{ minWidth: '20px' }} />
              <div className='font-bold hover:text-primary'>
                <a href='mailto:info@pharmcorps.com'>info@pharmcorps.com</a>
              </div>
            </div>
          </div>
        </div>
      </CommonLayout>
    </Layout>
  );
};

export default ContactPage;
